<template>
    <div class="home">
        <v-card>
            <v-container fluid>

                <v-row><v-col cols="12" sm="12" md="12" lg="12" xl="12"></v-col></v-row>

                <v-row>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-autocomplete label="Filter Broker"
                            v-model="selectedBrokerId" :items="brokerMap" item-text="broker" item-value="broker_id"
                            auto-select-first chips clearable deletable-chips small-chips></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-tabs>
                            <v-tab v-for="item in filterBookLists" :key="item" v-on:click="changeBook(item)">{{ item }}</v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-text-field class="mt-n2 mb-2" v-model="tableData.search" append-icon="mdi-search" label="Search" single-line hide-details>
                        </v-text-field>
                        <v-data-table dense :search="tableData.search" :headers="tableData.headers" :items="tableData.dataSource" class="elevation-1" :single-select="false" show-select v-model="tableData.selectedData" :items-per-page="tableData.itemsPerPage">
                        </v-data-table>
                    </v-col>
                </v-row>

            </v-container>
        </v-card>
    </div>
</template>
<script>
import { permission } from "@components/mixins/permission";
import { snackbar } from "@components/mixins/snackbar";
import { mapState } from "vuex";
import * as apiBook from "@components/api/pk/book";
import * as apiTradesLiveDetail from "@components/api/pk/trades/live-detail";

export default {
    components: {},
    mixins: [permission, snackbar],
    data () {
        return {
            selectedBrokerId: -1,
            selectedBroker: "",
            filterBookLists: [],
            selectedBook: "",
            tableData: {
                headers: [
                    // { text: "Book", value: "book" },
                    { text: "Symbol", value: "symbol" },
                    { text: "Net", value: "net" },
                    { text: "Buy", value: "buy" },
                    { text: "Sell", value: "sell" },
                    { text: "Profit", value: "profit" }
                ],
                dataSource: [],
                itemsPerPage: 20,
                search: ""
            },
            intervalTask: null
        };
    },
    computed: {
        ...mapState(["brokerMap"])
    },
    watch: {
        selectedBrokerId (nv, ov) {
            this.loadBook();
            this.tradesLiveDetailHandler().load();
        },
        selectedbook (nv, ov) {
            console.log("Access new ", nv);
        }
    },
    methods: {
        changeBook (book) {
            this.selectedBook = book;

            this.tableData.dataSource = [];

            this.tradesLiveDetailHandler().load();

            // clearInterval(this.intervalTask);
            // this.intervalTask = setInterval(() => {
            // 	this.tradesLiveDetailHandler().load()
            // }, 5000);
        },
        /**
         * Fetch users book group mapping
         * @return {[type]}
         */
        async loadBook () {
            const self = this;
            const params = { broker_id: this.selectedBrokerId };
            apiBook.fetch(params).then(res => {
                self.filterBookLists = res.data.map(item => item.book);
                if (self.filterBookLists.length !== 0) self.selectedBook = self.filterBookLists[0];
            });
        },
        tradesLiveDetailHandler () {
            const self = this;
            return {
                load () {
                    const params = { broker_id: self.selectedBrokerId, remove_tail: 1 };
                    apiTradesLiveDetail.fetch(params).then(res => {
                        self.snackBarSuccess("Load net position successfully");
                        self.tableData.dataSource = res.data.filter(item => item.book === self.selectedBook);
                    });
                }
            };
        }
    },
    created () {
        // 获取当前账户对应权限的broker及broker id
        const brokerPermission = this.loadDefaultBrokerData();
        this.selectedBrokerId = brokerPermission.selectedBrokerId;
        this.selectedBroker = brokerPermission.selectedBroker;
    },
    mounted () {
        this.loadBook();
        this.tradesLiveDetailHandler().load();

        // this.intervalTask = setInterval(() => {
        // 	this.tradesLiveDetailHandler().load()
        // }, 2000);
    },
    beforeDestroy () {
        // clearInterval(this.intervalTask);
    }
};
</script>
